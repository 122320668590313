.moves-header{
    height: 55px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
}

.moves-header-text{
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: #FFE769;
}

.moves-icon{
    padding: 0;
    margin: 0;
    height: 20px;
    width: 20px;
    object-fit: contain;
}

.moves-card{
    width: 90%;
    flex: 1;
    margin-left: 5%;
    padding: 0;
    overflow: auto;
    border-radius: 10px;
    border: 2px solid #444444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.moves-list{
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
}

.moves-card::-webkit-scrollbar { 
    background: transparent; 
    display: none;
}

.moves-card::-webkit-scrollbar-thumb { 
    background-color: transparent; 
    -webkit-border-radius: 1ex; 
}

.moves-list li{
    list-style: none;
    text-decoration: none;
    list-style-type: none;
}