.loading-dots {
    display: flex;
    align-items: center;
    grid-area: loading;
    height: 100%;
    width: 100%;
}

.position-left{
    justify-content: left;
}

.position-center{
    justify-content: center;
}

.position-right{
    justify-content: right;
}

.loading-dots div {
    display: inline-block;
    margin: 0 2px;
    border-radius: 50%;
    animation: loadingDots 1.2s infinite ease-in-out both;
}

.loading-dots div:nth-child(1) {
    animation-delay: -0.24s;
}

.loading-dots div:nth-child(2) {
    animation-delay: -0.12s;
}

.loading-dots div:nth-child(3) {
    animation-delay: 0;
}

.color-black{
    background-color: #000000;
}

.color-grey{
    background-color: #C0C0C0;
}

.size-small{
    width: 10px;
    height: 10px;
}

.size-medium{
    width: 15px;
    height: 15px;
}

.size-large{
    width: 30px;
    height: 30px;
}

@keyframes loadingDots {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}