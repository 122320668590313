.puzzle-lobby-title {
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
}

.puzzle-lobby-title-img {
    height: 40px;
    width: 40px;
}

.puzzle-lobby-title-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.puzzle-lobby-title h4 {
    padding: 0;
    margin: 0;
}

@media(max-height: 790px){
    .puzzle-lobby-title {
        flex-direction: row;
    }
}