.input-container {
    position: relative;
    width: 100%;
}

.input-label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    transition: 0.2s ease all;
    pointer-events: none;
}

.input-container.active .input-label {
    top: 0;
    left: 10px;
    font-size: 12px;
    color: #fff;
}

.input-container input {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background-color: #333333;
    border-radius: 15px;
    border: 2px solid #444444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #fff;
    box-sizing: border-box;
}

input:focus {
    outline: none; 
    border-color: #FFCD05;
}

input::placeholder {
    color: transparent;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
    background-color: #333333 !important;
    color: #fff !important;
    box-shadow: 0 0 0px 1000px #333333 inset !important;
    -webkit-text-fill-color: #fff !important;
}

.input-label-message {
    position: absolute;
    top: 24px;
    left: 12px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    transition: 0.2s ease all;
    pointer-events: none;
}

.input-container.active .input-label-message {
    top: 0;
    left: 10px;
    font-size: 12px;
    color: #fff;
}

.input-container.disabled {
    opacity: 0.6;
    pointer-events: none;
}

input:disabled {
    background-color: #444444;
    border-color: #555555;
    color: #888888;
    cursor: not-allowed;
}

.input-container.disabled .input-label {
    color: #666666;
}

input:disabled::placeholder {
    color: #555555;
}
