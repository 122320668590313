html,body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    background: #212121;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar { background: #181818; }
::-webkit-scrollbar-thumb { background-color: #3D3D3D; -webkit-border-radius: 1ex; }

.App{
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    top: 0;
    position: absolute;
}

h1{
    font-size: 36px;
    font-weight: normal;
}

h2{
    font-size: 30px;
    font-weight: normal;
}

h3{
    font-size: 24px;
    font-weight: normal;
}

h4{
    font-size: 20px;
    font-weight: normal;
}

h5{
    font-size: 18px;
    font-weight: normal;
}

h6{
    font-size: 14px;
    font-weight: normal;
}

p{
    font-size: 18px;
    font-weight: normal;
}

.grow {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
  
.grow:hover {
    transform: scale(1.1); 
}

@media(max-width: 900px){
    
    html, body{
        position: relative;
        width: 100% !important;
        overflow-x: hidden !important;
        overflow-y: auto;
        top: 0;
    }
}
