.content{
    width: 100%;
    height: 100%;
}

.container{
    margin-top: 12vh;
    width: 100%;
    height: 62vh;
    display: flex;
    flex-direction: row;
    gap: 5%;
    align-items: center;
    justify-content: center;
}

@media(max-width: 900px){
    .content{
        height: auto;
        margin-top: 18vh;
        padding-bottom: 4vh;
    }

    .container{
        flex-direction: column;
        width: 80%;
        margin-left: 10%;
        height: auto;
        gap: 4vh;
    }
}