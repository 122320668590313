.user-info{
    background-color: #181818;
    text-align: center;
    border-radius: 20px;
    height: 100%;
    width: 30vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.line {
    border: none;
    height: 0.5px;
    background-color: #D9D9D9;
    width: 70%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media(max-width: 900px){
    .user-info{
        width: 100%;
        height: 60vh;
    }
}