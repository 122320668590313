.message-entry{
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
}

.message-entry input{
    height: 30px;
    border-radius: 15px;
    border: 2px solid #000000;
    color: #000000;
    padding-left: 8px;
    position: absolute;
    width: 252px;
    padding-right: 40px;
}

.message-entry input::placeholder {
    color: #999;
    opacity: 1;
}

.message-entry img{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}