.profile-circle{
    height: 52px;
    width: 52px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-circle:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.profile-circle h4{
    font-weight: bold;
    color: #FFFFFF;
}