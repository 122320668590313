.forgot-password{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-password-content{
    width: 300px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-password-logo{
    position: absolute;
    top: 5%;
    justify-content: center;
}

.forgot-password-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.forgot-password-logo-img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
}

.forgot-password-form h2{
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0;
}

.forgot-password-form p{
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: center;
}

.forgot-password-selection{
    color: #FFCD05;
    cursor: pointer;
}

.forgot-password-logo-img {
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.forgot-password-logo-img:hover {
    transform: scale(1.1) rotate(5deg);
    filter: brightness(1.2);
}

.error-message{
    color: #FF4C4C !important;
}

.forgot-password-form-logo{
    width: 40px;
    height: 40px;
}

.forgot-password-form-logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}