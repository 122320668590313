.toggle-container {
    width: 120px;
    height: 60px;
    border-radius: 30px;
    background-color: #3d3d3d;
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    right: 30px;
    top: 20px;
}
  
.toggle-circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #ffcd05;
    position: absolute;
    top: 7.5px;
    z-index: 2;
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.toggle-circle img {
    width: 70%;
    height: 70%;
    object-fit: contain;
}
  
.toggle-left {
    transform: translateX(7.5px);
}
  
.toggle-right {
    transform: translateX(69.5px);
}
  
.toggle-icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: absolute;
    top: 0;
}
  
.toggle-icons img {
    width: 30px;
    height: 30px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}
  
.icon-left {
    position: absolute;
    left: 15px;
}
  
.icon-right {
    position: absolute;
    right: 35px;
}
  
.hidden-icon {
    opacity: 0;
    visibility: hidden;
}
  
.visible-icon {
    opacity: 1;
    visibility: visible;
}

@media(max-width: 900px){
    .toggle-container {
        position: relative;
        top: unset;
        right: unset;
        margin-left: 20px;
        margin-top: 20px;
    }
}
  
  
  