.bot-help{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #D9D9D9;
}

.bot-help h3{
    font-size: 18px;
    padding: 0;
    margin: 0;
}

.bot-help-selection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #FFFFFF !important;
}

.bot-help-flames{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.bot-help-flame{
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.bot-help-flame img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.help-description{
    text-align: right;
}