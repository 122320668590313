.active-game{
    display: flex;
    align-items: center;
    max-height: 78vh;
}

.board{
    width: 40vw;
    margin: auto;
}

@media(max-width: 900px){
    .active-game{
        height: auto;
        max-height: unset;
    }
    
    .board{
        width: 80vw;
        margin: auto;
    }
}

@media (min-width: 1400px) {
    .board {
        max-width: 35vw;
    }
}

@media (min-width: 1600px) {
    .board {
        max-width: 30vw;
    }
}

@media (min-width: 1800px) {
    .board {
        max-width: 25vw;
    }
}

@media (min-width: 2200px) {
    .board {
        max-width: 20vw;
    }
}