.search-bar{
    width: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    position: relative;
}

.search-bar input{
    width: 100%;
    height: 50px;
    border: 2px solid #FFE769;
    background: transparent;
    padding-left: 55px;
    color: #FFE769;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 18px;
}

.search-bar input::placeholder {
    color: #FFE769;
    opacity: 1;
}
  
.search-bar input::-ms-input-placeholder {
    color: #FFE769;
}

.search-img{
    position: absolute;
    pointer-events: none;
    height: 25px;
    width: 25px;
    object-fit: contain;
    top: 25%;
    left: 15px;
}