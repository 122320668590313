
.game-info{
    background-color: #3D3D3D;
    border-radius: 20px;
    margin-left: 5%;
    height: 150px;
    width: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
}

.game-stats{
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-left: 5%;
    justify-content: center;
    gap: 15px;
    
}

.game-info h3{
    font-size: 18px;
    padding: 0;
    margin: 0;
}

.game-row-1{
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    text-align: left;
    justify-content: left;
    align-items: center;
    gap: 10px;
    
}

.game-icon{
    height: 30px;
    color: #FFCD05;
}

.game-pieces-captured{
    height: 20px;
    flex: 1;
}

.game-pieces-captured-hidden{
    visibility: hidden;
    height: 20px;
    flex: 1;
}

.game-row-2{
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: left;
    align-items: center;
    gap: 5%;
    height: 20px;
}

.player-turn{
    background-color: #FFCD05;
}