.bot-option {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #3D3D3D;
    border-radius: 12px;
    cursor: pointer;
}

.bot-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 100%;
}

.bot-option-difficulty {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.bot-option-icon {
    height: 30px;
    width: 30px;
}

.bot-option-icon img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.bot-option-difficulty-txt,
.bot-option-range {
    font-size: 18px;
}

.option-selected{
    border: 0.5px solid #FFCD05;
}