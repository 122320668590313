.leader-list li{
    list-style: none;
    text-decoration: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.medal-list{
    color: #FFFFFF;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1.5fr 1fr;
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
}

.medal-list h3{
    font-size: 14px;
}

.medal-list img{
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
}

.odd-color{
    background-color: #3D3D3D;
}

.even-color{
    background-color: #212121;
}

.no-medal{
    visibility: hidden;
}

@media(max-width: 900px){
    .medal-list{
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
    }
}