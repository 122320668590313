.ingame-help-card{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.card-disable {
    background-color: #A1A1A1;
    filter: brightness(0.8);
    opacity: 0.6;
}

.card-active{
    background-color: #3D3D3D;
    filter: brightness(1);
    opacity: 1;
}

.ingame-help-card h5{
    margin: 0;
    padding: 0;
}

.ingame-help-icon{
    width: 20px;
    height: 20px;
}

.ingame-help-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ingame-help-tally{
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.tally-dot{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    
}

.active-tally{
    background-color: #FFCD05 !important;
}

.inactive-tally{
    background-color: #D9D9D9;
}