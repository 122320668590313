.leaderboard{
    background-color: #181818;
    text-align: center;
    border-radius: 20px;
    height: 100%;
    width: 30vw;
    display: flex;
    flex-direction: column;
}

.leaderboard h1{
    color: #FFE769;
    font-size: 36px;
    flex-shrink: 0;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 0;
}

.leader-list{
    text-align: left;
    height: 100%;
    width: 90%;
    overflow: auto;
    box-sizing: border-box;
    margin-left: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
    border: 2px solid #444444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
    flex: 1;
    margin-top: 0;
    padding: 0;
}

.leader-list::-webkit-scrollbar { 
    background: transparent; 
    display: none;
}

.leader-list::-webkit-scrollbar-thumb { 
    background-color: transparent; 
    -webkit-border-radius: 1ex; 
}

@media(max-width: 900px){
    .leaderboard{
        width: 100%;
        height: 50vh;
    }
}