.global{
    height: 100%;
    width: 15vw;
    display: flex;
    flex-direction: column;
    background-color: #181818;
    border-radius: 20px;
    align-items: center;
}

.global-stats{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.global-stats-row-1{
    display: flex;
    flex-direction: row;
}

.global-stats-icon{
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.global-stats-icon img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.global-stats-row-1 h1{
    font-size: 60px;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
}

.global-stats-row-1{
    display: flex;
    flex-direction: row;
}

.global-stats-row-2 h5{
    color: #FFFFFF;
    padding: 0;
    margin: 0;
}

.custom-line {
    width: 70%;
    color: #FFFFFF;
}

@media(max-width: 900px){
    .global{
        width: 100%;
        height: auto;
        background-size: contain;
    }

    .global-stats{
        margin-top: 25px;
        margin-bottom: 25px;
    }
}