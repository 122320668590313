.welcome{
    height: 100%;
    width: 30vw;
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.welcome-card{
    background-color: #181818;
    border-radius: 20px;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-card-content{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.welcome-icon{
    height: 70px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.5;
}

.welcome-icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.welcome-txt{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
}

.welcome-txt h3{
    margin: 0;
    padding: 0;
    color: #FFFFFF;
}

.welcome-txt p{
    margin: 0;
    padding: 0;
    color: #D9D9D9;
}

@media(max-width: 900px){
    .welcome{
        width: 100%;
        height: auto;
        background-size: contain;
        gap: 25px;
    }

    .welcome-card{
        width: 100%;
        height: 150px;
    }
}