
.game-card{
    background-color: #181818;
    border-radius: 20px;
    height: 100%;
    width: 30vw;
    display: flex;
    flex-direction: column;
}

@media(max-width: 900px){
    .game-card{
        width: 100%;
        height: 72vh;
    }
}