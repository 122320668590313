.lobby-options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'playersOption invitesOption';
    width: 100%;
    height: 175px;
    transition: 0.3s ease-in-out;
}

.players-option{
    grid-area: playersOption;
    color: #FFFFFF;
    text-align: center;
    border-radius: 20px 0 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.players-option img{
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.players-option h4{
    padding: 0;
    margin: 0;
}

.invites-option{
    grid-area: invitesOption;
    color: #FFFFFF;
    text-align: center;
    border-radius: 0 20px 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.invites-option img{
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.invites-option h4{
    padding: 0;
    margin: 0;
}

.selected{
    background-color: #181818;
    transition: 0.3s ease-in-out;
}

.unselected{
    background-color: #3D3D3D;
    transition: 0.3s ease-in-out;
}

@media(max-width: 900px){
    .players-option img{
        height: 30px;
    }

    .invite-option img{
        height: 30px;
    }
}