.details-form{
    display: flex;
    width: 80%;
    flex-direction: column;
    margin-top: 50px;
    gap: 20px;
    flex: 1;
}

.details{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.details h4{
    color: #FFFFFF;
    flex: 1;
    text-align: left;
    margin: 0;
    padding: 0;
}

.details-img{
    width: 42px;
    height: 42px;
}

.details-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.details-form p{
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #FFFFFF;
}

.forgot{
    color: #fff;
    cursor: pointer;
}

.edit{
    position: absolute;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 20px;
}

.edit-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;
}

.edit img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}