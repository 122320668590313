.odd-color{
    background-color: #3D3D3D;
}

.even-color{
    background-color: #212121;
}

.moves-line{
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 45px;
}

.moves-scroll{
    width: 105%;
}

.moves-line h3{
    font-size: 14px;
}

.move-number{
    flex: 1;
    text-align: center;
}

.move-white{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: center;
    gap: 4px;
}

.move-white img{
    height: 14px;
    width: auto;
}

.move-black{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: center;
    gap: 4px;
}

.move-black img{
    height: 14px;
    width: auto;
}