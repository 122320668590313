.lobby-menu{
    text-align: left;
    height: 100%;
    width: 90%;
    overflow: auto;
    box-sizing: border-box;
    margin-left: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
    border: 2px solid #444444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
}

.lobby-menu::-webkit-scrollbar { 
    background: transparent; 
    display: none;
}

.lobby-menu::-webkit-scrollbar-thumb { 
    background-color: transparent; 
    -webkit-border-radius: 1ex; 
}

.lobby-menu ul{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.players-list li{
    list-style: none;
    text-decoration: none;
    list-style-type: none;
}